import { render, staticRenderFns } from "./UpdatePassword.vue?vue&type=template&id=f4c27b64"
import script from "./UpdatePassword.vue?vue&type=script&lang=js"
export * from "./UpdatePassword.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports